var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "保单号", prop: "insuranceNo" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入保单号" },
                model: {
                  value: _vm.form.insuranceNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "insuranceNo", $$v)
                  },
                  expression: "form.insuranceNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "保险险种名称", prop: "insuranceCoverName" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择保险险种名称" },
                  model: {
                    value: _vm.form.insuranceCoverName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "insuranceCoverName", $$v)
                    },
                    expression: "form.insuranceCoverName",
                  },
                },
                _vm._l(_vm.insuranceCoverNameOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "免赔额", prop: "deductible" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入免赔额" },
                model: {
                  value: _vm.form.deductible,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deductible", $$v)
                  },
                  expression: "form.deductible",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "费率浮动率(元)", prop: "rateFloating" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入费率浮动率(元)" },
                model: {
                  value: _vm.form.rateFloating,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "rateFloating", $$v)
                  },
                  expression: "form.rateFloating",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "交强险浮动率(%)",
                prop: "compulsoryRateFloating",
              },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入交强险浮动率(%)" },
                model: {
                  value: _vm.form.compulsoryRateFloating,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "compulsoryRateFloating", $$v)
                  },
                  expression: "form.compulsoryRateFloating",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "保险金额/责任限额(元)",
                prop: "insuranceAmount",
              },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入保险金额/责任限额(元)" },
                model: {
                  value: _vm.form.insuranceAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "insuranceAmount", $$v)
                  },
                  expression: "form.insuranceAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "车上人员责任保险座位数",
                prop: "insuranceSeatNum",
              },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入车上人员责任保险座位数" },
                model: {
                  value: _vm.form.insuranceSeatNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "insuranceSeatNum", $$v)
                  },
                  expression: "form.insuranceSeatNum",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "保险费", prop: "insuranceCost" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入保险费" },
                model: {
                  value: _vm.form.insuranceCost,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "insuranceCost", $$v)
                  },
                  expression: "form.insuranceCost",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }