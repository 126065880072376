<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="保单号" prop="insuranceNo" >
        <a-input v-model="form.insuranceNo" placeholder="请输入保单号" />
      </a-form-model-item>
      <a-form-model-item label="保险险种名称" prop="insuranceCoverName" >
        <a-select placeholder="请选择保险险种名称" v-model="form.insuranceCoverName">
          <a-select-option v-for="(d, index) in insuranceCoverNameOptions" :key="index" :value="d.dictValue" >{{ d.dictLabel }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="免赔额" prop="deductible" >
        <a-input v-model="form.deductible" placeholder="请输入免赔额" />
      </a-form-model-item>
      <a-form-model-item label="费率浮动率(元)" prop="rateFloating" >
        <a-input v-model="form.rateFloating" placeholder="请输入费率浮动率(元)" />
      </a-form-model-item>
      <a-form-model-item label="交强险浮动率(%)" prop="compulsoryRateFloating" >
        <a-input v-model="form.compulsoryRateFloating" placeholder="请输入交强险浮动率(%)" />
      </a-form-model-item>
      <a-form-model-item label="保险金额/责任限额(元)" prop="insuranceAmount" >
        <a-input v-model="form.insuranceAmount" placeholder="请输入保险金额/责任限额(元)" />
      </a-form-model-item>
      <a-form-model-item label="车上人员责任保险座位数" prop="insuranceSeatNum" >
        <a-input v-model="form.insuranceSeatNum" placeholder="请输入车上人员责任保险座位数" />
      </a-form-model-item>
      <a-form-model-item label="保险费" prop="insuranceCost" >
        <a-input v-model="form.insuranceCost" placeholder="请输入保险费" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getCoverage, addCoverage, updateCoverage } from '@/api/iot/coverage'

export default {
  name: 'CreateForm',
  props: {
    insuranceCoverNameOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        insuranceNo: null,
        insuranceCoverName: null,
        deductible: null,
        rateFloating: null,
        compulsoryRateFloating: null,
        insuranceAmount: null,
        insuranceSeatNum: null,
        insuranceCost: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        insuranceNo: [
          { required: true, message: '保单号不能为空', trigger: 'blur' }
        ],
        insuranceCoverName: [
          { required: true, message: '保险险种名称(字典)不能为空', trigger: 'change' }
        ],
        insuranceCost: [
          { required: true, message: '保险费不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        insuranceNo: null,
        insuranceCoverName: null,
        deductible: null,
        rateFloating: null,
        compulsoryRateFloating: null,
        insuranceAmount: null,
        insuranceSeatNum: null,
        insuranceCost: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCoverage(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateCoverage(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addCoverage(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
