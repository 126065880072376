import request from '@/utils/request'

// 查询保险承保险种列表
export function listCoverage (query) {
  return request({
    url: '/iot/coverage/list',
    method: 'get',
    params: query
  })
}

// 查询保险承保险种详细
export function getCoverage (id) {
  return request({
    url: '/iot/coverage/' + id,
    method: 'get'
  })
}

// 新增保险承保险种
export function addCoverage (data) {
  return request({
    url: '/iot/coverage',
    method: 'post',
    data: data
  })
}

// 修改保险承保险种
export function updateCoverage (data) {
  return request({
    url: '/iot/coverage',
    method: 'put',
    data: data
  })
}

// 删除保险承保险种
export function delCoverage (id) {
  return request({
    url: '/iot/coverage/' + id,
    method: 'delete'
  })
}

// 导出保险承保险种
export function exportCoverage (query) {
  return request({
    url: '/iot/coverage/export',
    method: 'get',
    params: query
  })
}
